import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { css } from "@emotion/core"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const title = post.frontmatter.title.toString().replace(/[^a-z0-9+]+/gi, " ")
  return (
    <>
      <title> {title} </title>

      <div>
        <Layout>
          <div
            css={css`
              height: 100vh;
              max-width: 500px;
              margin-left: auto;
              margin-right: auto;
              order: 10;
            `}
          >
            <h1
              css={css`
                padding: 20px;
              `}
            >
              {post.frontmatter.title}
            </h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </Layout>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
